// Generated by Framer (c93245f)

import { addFonts, addPropertyControls, ComponentViewportProvider, ControlType, cx, CycleVariantState, getFonts, useActiveVariantCallback, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import NavigationPanel from "https://framerusercontent.com/modules/ju9zAjP9Xf5LLggIm2An/CAuc13hyUFMJ10iygkHc/ougqpdG8J.js";
const NavigationPanelFonts = getFonts(NavigationPanel);

const cycleOrder = ["FFAemjMnZ", "YcUt3uO9Z", "fIoaIbyf8", "T2o5X1GF7"];

const serializationHash = "framer-DuszV"

const variantClassNames = {FFAemjMnZ: "framer-v-pfiunq", fIoaIbyf8: "framer-v-nowj7c", T2o5X1GF7: "framer-v-1l55n9r", YcUt3uO9Z: "framer-v-1oet2lb"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {"Navigation 1": "FFAemjMnZ", "Navigation 2": "YcUt3uO9Z", "Navigation 3": "fIoaIbyf8", "Navigation 4": "T2o5X1GF7"}

const getProps = ({height, id, tap, width, ...props}) => { return {...props, eRctTDLt5: tap ?? props.eRctTDLt5, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "FFAemjMnZ"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;tap?: any; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, eRctTDLt5, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "FFAemjMnZ", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onTapvp2pv2 = activeVariantCallback(async (...args) => {
setGestureState({isPressed: false})
if (eRctTDLt5) {
const res = await eRctTDLt5(...args);
if (res === false) return false;
}
})

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} className={cx(serializationHash, ...sharedStyleClassNames, "framer-pfiunq", className, classNames)} data-framer-name={"Navigation 1"} data-highlight layoutDependency={layoutDependency} layoutId={"FFAemjMnZ"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={onTapvp2pv2} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{backgroundColor: "rgb(255, 255, 255)", borderBottomLeftRadius: 12, borderBottomRightRadius: 12, borderTopLeftRadius: 12, borderTopRightRadius: 12, boxShadow: "0px 5px 15px 2px rgba(170, 170, 170, 0.2)", ...style}} {...addPropertyOverrides({fIoaIbyf8: {"data-framer-name": "Navigation 3"}, T2o5X1GF7: {"data-framer-name": "Navigation 4"}, YcUt3uO9Z: {"data-framer-name": "Navigation 2"}}, baseVariant, gestureVariant)}><ComponentViewportProvider ><motion.div className={"framer-h10ddb-container"} layoutDependency={layoutDependency} layoutId={"xNxk0ksC5-container"}><NavigationPanel height={"100%"} id={"xNxk0ksC5"} layoutId={"xNxk0ksC5"} style={{width: "100%"}} variant={"hZKhnh7pl"} width={"100%"} {...addPropertyOverrides({fIoaIbyf8: {variant: "CsJgHlGiz"}, T2o5X1GF7: {variant: "Bn0HC15Pm"}, YcUt3uO9Z: {variant: "QKs7kPcwR"}}, baseVariant, gestureVariant)}/></motion.div></ComponentViewportProvider></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-DuszV.framer-12rcmc4, .framer-DuszV .framer-12rcmc4 { display: block; }", ".framer-DuszV.framer-pfiunq { align-content: flex-start; align-items: flex-start; cursor: pointer; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 0px; height: min-content; justify-content: flex-start; overflow: hidden; padding: 0px 0px 0px 0px; position: relative; width: 660px; will-change: var(--framer-will-change-override, transform); }", ".framer-DuszV .framer-h10ddb-container { flex: 1 0 0px; height: auto; position: relative; width: 1px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-DuszV.framer-pfiunq { gap: 0px; } .framer-DuszV.framer-pfiunq > * { margin: 0px; margin-left: calc(0px / 2); margin-right: calc(0px / 2); } .framer-DuszV.framer-pfiunq > :first-child { margin-left: 0px; } .framer-DuszV.framer-pfiunq > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 427
 * @framerIntrinsicWidth 660
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","auto"]},"YcUt3uO9Z":{"layout":["fixed","auto"]},"fIoaIbyf8":{"layout":["fixed","auto"]},"T2o5X1GF7":{"layout":["fixed","auto"]}}}
 * @framerVariables {"eRctTDLt5":"tap"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerYrRKYizsd: React.ComponentType<Props> = withCSS(Component, css, "framer-DuszV") as typeof Component;
export default FramerYrRKYizsd;

FramerYrRKYizsd.displayName = "1 - Toggle box";

FramerYrRKYizsd.defaultProps = {height: 427, width: 660};

addPropertyControls(FramerYrRKYizsd, {variant: {options: ["FFAemjMnZ", "YcUt3uO9Z", "fIoaIbyf8", "T2o5X1GF7"], optionTitles: ["Navigation 1", "Navigation 2", "Navigation 3", "Navigation 4"], title: "Variant", type: ControlType.Enum}, eRctTDLt5: {title: "Tap", type: ControlType.EventHandler}} as any)

addFonts(FramerYrRKYizsd, [{explicitInter: true, fonts: []}, ...NavigationPanelFonts], {supportsExplicitInterCodegen: true})